import { AttachmentType } from '../types';

export const AP_STATUS_DRAFT = 'DRAFT';
export const AP_STATUS_VERIFIED = 'VERIFIED';
export const AP_STATUS_POSTED = 'POSTED';
export const AP_STATUS_PAID = 'PAID';
export const AP_STATUS_REVERSED = 'REVERSED';
export const AP_STATUS_WAITING_FOR_AP = 'WAITING_FOR_AP';
export const AP_STATUS_SYNC_FAILED = 'SYNC_FAILED';

export const PO_STATUS_DRAFT = 'DRAFT';
export const PO_STATUS_CONFIRMED = 'CONFIRMED';
export const PO_STATUS_REJECTED = 'REJECTED';
export const PO_STATUS_SYNC_IN_PROGRESS_CREATE_PO = 'SYNC_IN_PROGRESS_CREATE_PO';
export const PO_STATUS_SYNC_SUCCESS = 'SYNC_SUCCESS';
export const PO_STATUS_SYNC_FAILED = 'SYNC_FAILED';
export const PO_STATUS_FAILED = 'FAILED';
export const PO_STATUS_SYNC_FAILED_CREATE_PO = 'SYNC_FAILED_CREATE_PO';

export const ORDER_STATUS_WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT';
export const ORDER_STATUS_WAITING_FOR_CONFIRMATION = 'WAITING_FOR_CONFIRMATION';
export const ORDER_STATUS_ORDER_PREPARED = 'ORDER_PREPARED';
export const ORDER_STATUS_READY_TO_SHIP = 'READY_TO_SHIP';
export const ORDER_STATUS_BEING_SHIPPED = 'BEING_SHIPPED';
export const ORDER_STATUS_ORDER_DELIVERED = 'ORDER_DELIVERED';
export const ORDER_STATUS_ORDER_COMPLETED = 'ORDER_COMPLETED';
export const ORDER_STATUS_ORDER_CANCELLED = 'ORDER_CANCELLED';

export const ATTACHMENT_TYPE_TRAVEL_DOCUMENT = 'TRAVEL_DOCUMENT';
export const ATTACHMENT_TYPE_BAST = 'BAST';
export const ATTACHMENT_TYPE_PROFORMA_INVOICE = 'PROFORMA_INVOICE';
export const ATTACHMENT_TYPE_INVOICE = 'INVOICE';

export const VALUE_CHAIN_SHRIMP = 'SHRIMP';
export const VALUE_CHAIN_FISH = 'FISH';

export const STATUS_SYNC_ODOO_SUCCESS = 'SUCCESS';
export const STATUS_SYNC_ODOO_NOT_SYNCED = 'NOT_SYNCED';

export const PAYMENT_TYPE_CASH = 'CASH';
export const PAYMENT_TYPE_TENOR = 'TENOR';

export const PLATFORM_FEEDHUB = 'feedhub';
export const PLATFORM_EFISHERYKU = 'efisheryku';

interface IAttachmentEnums {
  key?: number;
  type: AttachmentType;
  label: string;
  notes: string;
  hideButton?: boolean;
  disabled?: boolean;
}

export const ATTACHMENT_ENUMS: IAttachmentEnums[] = [
  {
    key: 1,
    type: ATTACHMENT_TYPE_TRAVEL_DOCUMENT,
    label: 'Surat Jalan',
    notes: 'Upload lampiran surat jalan di sini. Dokumen surat jalan akan digunakan untuk proses request approval.',
    disabled: false,
    hideButton: false,
  },
  {
    key: 2,
    type: ATTACHMENT_TYPE_BAST,
    label: 'BAST (Berita Acara Serah Terima)',
    notes: 'Upload lampiran BAST di sini. Dokumen ini akan digunakan untuk proses request approval.',
    disabled: true,
    hideButton: true,
  },
  {
    key: 3,
    type: ATTACHMENT_TYPE_PROFORMA_INVOICE,
    label: 'Proforma Invoice',
    notes:
      'Lampiran proforma invoice nantinya akan muncul di sini. Dokumen ini bisa kamu upload lewat fitur Advance Payment.',
    disabled: true,
    hideButton: true,
  },
  {
    key: 4,
    type: ATTACHMENT_TYPE_INVOICE,
    label: 'Invoice',
    notes:
      'Lampiran invoice nantinya akan muncul di sini. Dokumen invoice baru akan muncul di sini setelah semua data berhasil proses.',
    disabled: true,
    hideButton: false,
  },
];
