/**
 * This file is copied from `core-internal-tools`
 * https://bitbucket.org/efishery/internal-tools/src/main/src/dataProviders/authDataProvider.ts
 */
import { AuthBindings } from '@refinedev/core';
import { auth, getAccessToken } from './libs';
import { deleteRedirectPath } from './store';

export const authProvider: AuthBindings = {
  login: async ({ grantType, code, redirectTo }) => {
    try {
      const { accessToken } = await auth.persistExchange(code, grantType);
      await auth.getProfile(accessToken);
      const origin = window.location.origin;

      window.location.assign(`${origin}${redirectTo}`);
      deleteRedirectPath();

      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        redirectTo: '/login',
      };
    }
  },
  logout: async () => {
    await auth.logout();

    return {
      success: true,
      redirectTo: '/login',
    };
  },
  check: async () => {
    const accessToken = getAccessToken();
    if (!accessToken) {
      return {
        authenticated: false,
        redirectTo: '/login',
      };
    }

    return {
      authenticated: true,
    };
  },
  getIdentity: async () => {
    const profile = await auth.getProfile();

    return profile.data;
  },
  onError: async (error) => {
    return { error };
  },
};
