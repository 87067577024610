import { PROCUREMENT_SERVICE_URL } from '@/config';
import { CreateHttp } from '@/utils/http';
import { type DataProvider } from '@refinedev/core';
import {
  ORDER_STATUS_BEING_SHIPPED,
  ORDER_STATUS_ORDER_PREPARED,
  ORDER_STATUS_WAITING_FOR_CONFIRMATION,
} from '../constants/enums';

const apiUrl = PROCUREMENT_SERVICE_URL;
const httpClient = CreateHttp(apiUrl);

export const purchaseOrderDropshipResourceName = 'po-dropship';

const purchaseOrderDropshipDataProvider: Omit<
  Required<DataProvider>,
  'createMany' | 'updateMany' | 'deleteMany' | 'getMany' | 'custom' | 'deleteOne' | 'getApiUrl' | 'create' | 'update'
> = {
  getList: async ({ resource, pagination, filters }) => {
    const params: Record<string, string | number> = {};

    const { pageSize = 10, current = 1, mode = 'off' } = pagination ?? {};
    if (mode === 'server') {
      params.limit = pageSize;
      params.page = current;
      params.is_dropship = 'true';
      params.show_all = 'true';
      params.sort_by = 'po.created_at';
      params.sort_order = 'asc';
    }

    filters?.forEach((f) => {
      if ('field' in f) {
        params[f['field']] = f.value;
      }
    });

    if (params.order_status === undefined) {
      params.order_status = `${ORDER_STATUS_WAITING_FOR_CONFIRMATION},${ORDER_STATUS_ORDER_PREPARED},${ORDER_STATUS_BEING_SHIPPED}`;
    }

    return await httpClient.get(resource, { params }).then((res) => {
      return {
        data: res.data.data,
        total: res.data?.meta?.total_data | 0,
      };
    });
  },
  getOne: async ({ resource, id }) => {
    try {
      return await httpClient.get(`${resource}/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err;
    }
  },
};

export default purchaseOrderDropshipDataProvider;
