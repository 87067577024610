import '@refinedev/antd/dist/reset.css';
import './utils/styles/global.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { ConfigProvider } from 'antd';
import { EfisheryProvider } from '@efishery/sdk-reactjs';
import { FlagProvider } from '@onefish/flag';
import { ColorModeContextProvider } from './utils/contexts/color-mode';
import { AUTH_V2_CLIENT_ID, AUTH_V2_STAGE, AUTH_REDIRECT_URL, APP_ENVIRONMENT, FLAGGING_SERVICE_URL } from './config';
import colors from './utils/styles/colors';

import App from './App';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
const authRedirect = APP_ENVIRONMENT === 'preview' ? `${window.location.origin}/redirect` : AUTH_REDIRECT_URL;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.tz.setDefault('Asia/Jakarta');

root.render(
  <React.StrictMode>
    <ColorModeContextProvider>
      <FlagProvider flagUrl={FLAGGING_SERVICE_URL}>
        <EfisheryProvider
          clientId={AUTH_V2_CLIENT_ID}
          stage={AUTH_V2_STAGE}
          redirectUrl={authRedirect}
          failedRedirectUrl={authRedirect}
        >
          <ConfigProvider
            form={{
              scrollToFirstError: {
                // You can read about `boundary` here
                // https://github.com/scroll-into-view/compute-scroll-into-view#boundary
                //
                // this function will called several time by <Form/> element
                // until the `parent` is <body/> if it return `true`,
                //
                // the first call `parent` value is the field that return error, so
                // we just want to scroll to that element, and don't need reach to
                // body
                boundary: (parent) => {
                  parent.scrollIntoView({ block: 'center' });
                  return false;
                },
              },
            }}
            theme={{
              token: {
                fontSize: 14,
                fontFamily: 'Inter, sans-serif',
                colorPrimary: colors['--color-observatory-500'],
                colorLink: colors['--color-observatory-500'],
                colorText: colors['--color-black-400'],
              },
            }}
          >
            <App />
          </ConfigProvider>
        </EfisheryProvider>
      </FlagProvider>
    </ColorModeContextProvider>
  </React.StrictMode>,
);
