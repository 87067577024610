/**
 * This file is copied from `core-internal-tools`
 * https://bitbucket.org/efishery/internal-tools/src
 */
import { EMAIL_PROFILE_NOT_FOUND, EXPIRED_REFRESH_TOKEN } from '@/components/CanAccess/constant';
import { AUTH_SERVICE_URL, AUTH_V2_DOMAIN } from '@/config';
import { CreateHttp } from '@/utils/http';
import { type CanParams, type CanReturnType } from '@refinedev/core';
import { auth } from './libs';

const Http = CreateHttp(AUTH_SERVICE_URL);

export const accessControlProvider = {
  async can({ resource, action }: CanParams): Promise<CanReturnType> {
    const profile = await auth.getProfile();
    const email = profile.data?.email;
    if (!email) {
      return {
        can: false,
        reason: EMAIL_PROFILE_NOT_FOUND,
      };
    }
    try {
      const refreshToken = auth.getRefreshToken();
      if (refreshToken === undefined) {
        // re-login
        return {
          can: false,
          reason: EXPIRED_REFRESH_TOKEN,
        };
      }

      const resp = await Http.post('/rbac/enforce', {
        resource,
        action,
        domain: AUTH_V2_DOMAIN,
        user: email,
      });

      return { can: resp.data.data.eligible };
    } catch (error: unknown) {
      if (error instanceof Error) {
        return {
          can: false,
          reason: error.message,
        };
      }

      throw error;
    }
  },
};
