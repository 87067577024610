import { Auth } from '@efishery/sdk-core';
import { AUTH_V2_CLIENT_ID, AUTH_V2_STAGE } from '@/config';

export const auth = new Auth(AUTH_V2_CLIENT_ID, AUTH_V2_STAGE);

export const getAccessToken = () => {
  const accessTokenKey = auth.getAccessTokenKey();
  return document.cookie
    .split('; ')
    .find((row) => {
      return row.startsWith(`${accessTokenKey}=`);
    })
    ?.split('=')[1];
};
